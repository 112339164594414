import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import "../../sass/footer.scss";
import vec from "../../asset/Group37.svg";
import logo from "../../asset/logo_white.png";
import { Link } from "react-router-dom";
import { FiFacebook, FiInstagram, FiLinkedin } from "react-icons/fi";
import { HashLink } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import TermsAndConditionsPage from "../../pages/termCondition/termCondition";
import PrivacyPolicyPage from "../../pages/termCondition/policy";

// Style to center the modal content
const modalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    zIndex: 9999,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "500px",
    width: "90%",
    maxHeight: "80vh", // Set the maximum height of the modal content
    overflowY: "auto", // Enable vertical scroll if content exceeds the height
    padding: "20px",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    background: "#fff",
  },
};

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPolicyModal, setShowPolicyModal] = useState(false);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#terms") {
      setShowTermsModal(true);
    } else if (hash === "#policy") {
      setShowPolicyModal(true);
    }
  }, [location]);

  const handleOpenTermsModal = () => {
    setShowTermsModal(true);
  };

  const handleOpenPolicyModal = () => {
    setShowPolicyModal(true);
  };

  const handleCloseModal = () => {
    setShowTermsModal(false);
    setShowPolicyModal(false);
    navigate("/");
  };

  return (
    <section className="footer  flex flex-col">
      {/* Rest of the code */}
      <div className="footer-body w-full">
        <figure
          className="image"
          style={{ margin: "auto", marginBottom: "80px" }}
        >
          <img src={vec} alt="footer-plc" />
        </figure>

        <div className="footer-column flex w-full ">
          <div className="text-and-logo-container flex justify-between items-center w-full">
            <div className="text-container">
              <p className="footer-title">Turning image into 3dm file</p>
              <p className="footer-subtitle mt-5">
                High level experience in 3d Jewellery modeling of any stuff you
                can think of.
              </p>
            </div>

            <Link to="/" className="foot-logo">
              <img src={logo} alt="nav-logo" className="logoimage" />
            </Link>
          </div>
          <br />
          <br />
          <HashLink
            smooth
            to="/#terms"
            className="btn terms-btn" // Added a custom class for styling
            onClick={(e) => {
              e.preventDefault();
              handleOpenTermsModal();
            }}
          >
            Terms and Conditions
          </HashLink>
          <br />
          <br />
          <HashLink
            smooth
            to="/#policy"
            className="btn policy-btn" // You can also apply styling directly if needed
            onClick={(e) => {
              e.preventDefault();
              handleOpenPolicyModal();
            }}
          >
            Privacy Policy
          </HashLink>
        </div>

        <br />
        <div className="icons-copyright mt-2 w-full">
          <div className="has-text-centered-mobile">
            <p className="copyrights text-left sm:text-right pt-0 md:pt-6">
              ©2024 Verjew. ALL RIGHTS RESESRVED
            </p>
          </div>
          <div className="footer-icons-set">
            <a
              href="https://www.instagram.com/verjewofficial"
              target="_blank"
              rel="noreferrer"
              className="nav-icons"
            >
              <FiInstagram style={{ fontSize: "26px" }} />
            </a>

            <a
              href="https://www.facebook.com/verjewofficial/"
              target="_blank"
              rel="noreferrer"
              className="nav-icons"
            >
              <FiFacebook style={{ fontSize: "26px" }} />
            </a>

            <a
              href="https://www.linkedin.com/company/3dspot/"
              target="_blank"
              rel="noreferrer"
              className="nav-icons"
            >
              <FiLinkedin style={{ fontSize: "26px" }} />
            </a>
          </div>
        </div>
      </div>
      <FloatingWhatsApp
        phoneNumber="19177643908"
        accountName="Verjew"
        allowEsc
        allowClickAway
        notification
        avatar={logo}
        notificationSound
      />

      {/* Rest of the code */}
      <ReactModal
        isOpen={showTermsModal}
        onRequestClose={handleCloseModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <button
          className="close-btn"
          onClick={handleCloseModal}
          aria-label="Close"
        >
          &#10005; {/* Unicode character for "X" */}
        </button>

        <TermsAndConditionsPage />
      </ReactModal>

      <ReactModal
        isOpen={showPolicyModal}
        onRequestClose={handleCloseModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <button
          className="close-btn"
          onClick={handleCloseModal}
          aria-label="Close"
        >
          &#10005; {/* Unicode character for "X" */}
        </button>

        <PrivacyPolicyPage />
      </ReactModal>
    </section>
  );
};

export default Footer;
