import React, { useState, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import Navbar from "../../components/navbar/Navbar";
import "react-toastify/dist/ReactToastify.css";
import "../../sass/createorder.scss";
import { apiInstance } from "../../config/apiConfig";
import { TailSpin } from "react-loader-spinner";


const CreateProject = () => {
  const [images, setImages] = useState([]);
  const [title, setTitle] = useState("");
  const [note, setNote] = useState("");
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  // Handles changes in the file input field
  const handleFileChange = (e) => {
    setImages([...images, ...Array.from(e.target.files)]);
  };

  // Handles the removal of a selected image
  const handleRemoveImage = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
    // Reset file input if all images are removed
    if (newImages.length === 0 && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  // Handles files dropped onto the drop area
  const handleDrop = (e) => {
    e.preventDefault();
    setImages([...images, ...Array.from(e.dataTransfer.files)]);
  };

  // Handles the form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!title.trim() || !note.trim() || images.length === 0) {
      toast.error("Please fill all fields and add at least one image.");
      return;
    }

    setIsLoading(true); // Start loading
    const formData = new FormData();
    formData.append("title", title);
    formData.append("note", note);
    images.forEach((image) => formData.append("images", image));

    try {
      await apiInstance.post("/api/order/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      toast.success("Project created successfully!");
      setTitle("");
      setNote("");
      setImages([]);
      fileInputRef.current.value = "";
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Failed to create project.");
    } finally {
      setIsLoading(false); // End loading
    }
  };

  return (
    <div className="create-project-container">
      <Navbar />
      <h2 className="text-2xl font-bold mb-4">Create New Project</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div
          className="form-section"
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
          style={{
            border: "2px dashed #ccc",
            textAlign: "center",
            padding: "20px",
            position: "relative",
          }}
        >
          <label htmlFor="images" className="block mb-2 cursor-pointer">
            {/* <div>Add Reference Images*</div> */}
            <div style={{ fontSize: "48px" }}>+</div>
          </label>
          <input
            ref={fileInputRef}
            id="images"
            type="file"
            multiple
            onChange={handleFileChange}
            className="input input-bordered w-full"
            accept="image/jpeg,image/png,image/gif"
            style={{ display: "none" }}
          />
          <p className="text-sm text-gray-600">Add Reference Images*</p>
          <div className="image-preview-container">
            {images.map((file, index) => (
              <div
                key={index}
                className="image-preview"
                style={{
                  position: "relative",
                  display: "inline-block",
                  marginRight: "10px",
                }}
              >
                <img
                  src={URL.createObjectURL(file)}
                  alt="Preview"
                  style={{ width: "100px", height: "100px" }}
                />
                <button
                  type="button"
                  onClick={() => handleRemoveImage(index)}
                  style={{
                    position: "absolute",
                    top: "0px", // Adjust for alignment
                    right: "0px", // Adjust for alignment
                    background: "rgba(255, 255, 255, 0.7)", // Semi-transparent white background
                    borderRadius: "50%",
                    width: "24px", // Fixed size for the button
                    height: "24px", // Fixed size for the button
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "none",
                    cursor: "pointer",
                    outline: "none",
                  }}
                  aria-label="Remove image"
                >
                  <svg
                    viewBox="0 0 24 24"
                    width="16"
                    height="16" // SVG size
                    stroke="red" // SVG stroke color
                    strokeWidth="2" // SVG stroke width
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>
            ))}
          </div>
        </div>

        <div className="form-section">
          {isLoading && (
            <div
              style={{
                position: "fixed",
                bottom: "20px",
                right: "20px",
                zIndex: 1000, // Make sure it's on top
              }}
            >
              <TailSpin color="red" height={50} width={50} />
            </div>
          )}
          <label htmlFor="title" className="block mb-2">
            Title*
          </label>
          <input
            id="title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Add a title for your model e.g. Halo Prism"
            className="input input-bordered w-full"
          />
        </div>

        <div className="form-section">
          <label htmlFor="note" className="block mb-2">
            Leave a note*
          </label>
          <textarea
            id="note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Add any special instructions here"
            className="input input-bordered w-full"
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Create Project
        </button>
      </form>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />{" "}
    </div>
  );
};

export default CreateProject;
