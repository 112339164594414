import React, { useState, useEffect } from "react";
import "../sass/homepage.scss"; // Optimized imports
import Navbar from "../components/navbar/Navbar";
import Create from "../pages/components/create/Create";
import Footer from "../components/footer/Footer";
import buttonImage from "../asset/Frame 218.svg"; // Naming convention
import jewel from "../asset/6jewell.png"; // Naming convention
import animationData from "../asset/lf30_editor_bjixuktb.json"; // Direct import of animation data
import Gallery from "./components/gallery/Gallery";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import Faq from "./components/faq/faq";
import { useAuth } from "../auth/AuthContext";
import { useNavigate } from "react-router-dom";

// Moved defaultOptions outside the component to avoid redeclaration on each render
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function HomePage() {
  const navigate = useNavigate();
  const { isLoggedIn, userRole } = useAuth();

  const handleButtonClick = () => {
    if (isLoggedIn) {
      navigate("/dashboard/create"); // Navigate to the desired page if logged in
    } else {
      navigate("/login"); // Redirect to login if not logged in
    }
  };

  return (
    <section id="policy">
      <div className="home-page">
        <div className="drawer drawer-end">
          <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
          <div className="drawer-content">
            {/* Label for drawer removed since it's empty and not used */}
            <Navbar />
            <section className="hero top-section-hero">
              <div className="hero-body">
                <div className="flex justify-center items-center max-w-screen flex-col lg:flex-row">
                  <div className="basis-1/2 text-left">
                    <div className="mt-12">
                      <div className="title-container">
                        <h1 className="title">
                          Custom Jewelry <br />
                          Design Files <br />
                          for Your Shop
                        </h1>
                        <img
                          src={jewel}
                          alt="Decorative Image"
                          className="title-image jewel-image"
                        />
                      </div>

                      <h2 className="subtitle ">
                        Order 3DM files <br />
                        <span>
                          at a <span style={{ color: "red" }}>flat rate</span>{" "}
                          of $69
                        </span>
                      </h2>
                      <div
                        className="transparent-button"
                        onClick={handleButtonClick} // Use onClick handler directly on the div
                        role="button" // Accessibility improvement
                        tabIndex={0} // Make it focusable
                        onKeyDown={(e) =>
                          e.key === "Enter" && handleButtonClick()
                        } // Accessibility: trigger on enter key
                      >
                        <img
                          src={buttonImage}
                          alt="Place Order"
                          className="mt-6"
                        />
                      </div>

                      <div className="mt-6 hidden lg:flex w-[80px]">
                        <Lottie
                          options={defaultOptions}
                          height={150}
                          width={150}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Create />
            <Gallery id="gallery" />

            <Faq />
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}

export default HomePage;
