import React from "react";
import "../../sass/terms.scss";


const TermsAndConditionsPage = () => {
  return (
    <div className="terms-conditions">
      <h1 className="text-2xl font-bold mb-4 text-center">
        Terms and Conditions
      </h1>
      <ul className="grid grid-cols-1 sm:grid-cols-1 gap-4">
        <li>
          <h3 className="text-lg font-bold mb-2">Payment Terms:</h3>
          <ul className="pl-4 list-disc">
            <li className="text-justify">
              The client is required to make the full payment upfront before we
              commences any work on the project. This change ensures that
              both parties are fully committed to the project from the outset.
              Upon receipt of the payment, we will proceed with the
              creation of the 3D model as per the agreed specifications.
            </li>
          </ul>
        </li>
        <li>
          <h3 className="text-lg font-bold mb-2">Non-Refundable Policy:</h3>
          <ul className="pl-4 list-disc">
            <li className="text-justify">
              {" "}
              All payments made by the client to Verjew are non-refundable.
              Once the client has made a payment, it cannot be refunded.
            </li>
          </ul>
        </li>
        <li>
          <h3 className="text-lg font-bold mb-2">Intellectual Property:</h3>
          <ul className="pl-4 list-disc">
            <li className="text-justify">
              The client retains all rights and ownership to any intellectual
              property related to the 3D printable model. Verjew will not claim
              any ownership or rights to the model unless and until the client
              refuses to pay the due amount.
            </li>
          </ul>
        </li>
        <li>
          <h3 className="text-lg font-bold mb-2">Delivery Time:</h3>
          <ul className="pl-4 list-disc">
            <li className="text-justify">
              Verjew shall make every effort to deliver the 3D printable model
              within the agreed-upon timeline. However, Verjew shall not be
              held responsible for any delays due to unforeseen circumstances
              beyond their control
            </li>
          </ul>
        </li>
        <li>
          <h3 className="text-lg font-bold mb-2">Quality of Work:</h3>
          <ul className="pl-4 list-disc">
            <li className="text-justify">
              Verjew shall make every effort to ensure that the 3D model meets
              the client's requirements and expectations. If the client is not
              satisfied with the quality of work, Verjew shall make reasonable
              efforts to resolve any issues. However, the client shall not be
              entitled to a refund for quality issues.
            </li>
          </ul>
        </li>
        <li>
          <h3 className="text-lg font-bold mb-2">Confidentiality:</h3>
          <ul className="pl-4 list-disc">
            <li className="text-justify">
              Verjew shall maintain the confidentiality of all information
              provided by the client. Verjew shall not disclose any information
              to third parties without the client's consent.
            </li>
          </ul>
        </li>
        <li>
          <h3 className="text-lg font-bold mb-2">Governing Law:</h3>
          <ul className="pl-4 list-disc">
            <li className="text-justify">
              This agreement shall be governed by and construed in accordance
              with the laws of the jurisdiction in which Verjew is located.{" "}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default TermsAndConditionsPage;
