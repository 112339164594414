import React, { useState, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import { apiInstance } from "../../config/apiConfig";
import { useAuth } from "../../auth/AuthContext";
import Navbar from "../../components/navbar/Navbar";
import "../../sass/orders.scss"; // Ensure this includes the updated CSS
import JSZip from "jszip";
import { saveAs } from "file-saver";
import io from "socket.io-client";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../payment/checkout";
import PaymentRequestForm from "../payment/payment";
import { TailSpin } from "react-loader-spinner";

const stripePromise = loadStripe(
  "pk_test_51MVvSRKDWovJoqmyX3JJuu4hFVfRUGKI97wVzd3ZWLzGlxNuoZo6McEm2n8L76XM4kpEJLX50uQ7PZrn4K4bDwUx00pCm6988g"
);

const socket = io("https://verjew-api.onrender.com"); // Use your server URL

const OrdersList = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showSidebar, setShowSidebar] = useState(false); // State to control sidebar visibility
  const { isLoggedIn, userRole, user } = useAuth();
  const [priceValue, setPriceValue] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [typing, setTyping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [typingMessage, setTypingMessage] = useState("");
  const [typingTimeoutId, setTypingTimeoutId] = useState(null);
  const [statusFilter, setStatusFilter] = useState("Reviewed");
  const filteredOrders = orders.filter((order) => {
    if (statusFilter === "Reviewed") {
      return order.status === "Reviewed" || order.status === "Pending";
    }
    return order.status === statusFilter;
  });

  const sidebarRef = useRef(); // Create a ref for the sidebar
  const messagesEndRef = useRef(null);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [isreviewOpen, setIsreviewOpen] = useState(false);
  const [isstatusOpen, setIsstatusOpen] = useState(false);

  const togglePaymentDetails = () => {
    setIsPaymentOpen(!isPaymentOpen);
  };
  const togglereviewDetails = () => {
    setIsreviewOpen(!isreviewOpen);
  };
  const togglestatusDetails = () => {
    setIsstatusOpen(!isstatusOpen);
  };

  useEffect(() => {
    socket.on("price update", (update) => {
      setOrders((currentOrders) =>
        currentOrders.map((order) =>
          order._id === update.orderId
            ? { ...order, price: update.newPrice }
            : order
        )
      );
    });

    socket.on("status update", (update) => {
      setOrders((currentOrders) =>
        currentOrders.map((order) =>
          order._id === update.orderId
            ? { ...order, status: update.newStatus }
            : order
        )
      );
    });

    socket.on("chat message", (newMessage) => {
      setChatMessages((prevMessages) => {
        // Check if the message is already present in the state
        if (prevMessages.find((msg) => msg.id === newMessage.id)) {
          return prevMessages; // Return the existing state if duplicate
        } else {
          return [...prevMessages, newMessage]; // Add new message to state
        }
      });
    });

    socket.on("typing", (data) => {
      setTypingMessage(`${data.userRole} is typing...`);
      clearTimeout(typingTimeoutId);
      setTypingTimeoutId(
        setTimeout(() => {
          setTypingMessage("");
        }, 3000)
      );
    });

    return () => {
      socket.off("price update");
      socket.off("status update");
      socket.off("chat message");
      socket.off("typing");
    };
  }, [typingTimeoutId]);

  useEffect(() => {
    if (messagesEndRef.current) {
      const element = messagesEndRef.current;
      element.scrollTop = element.scrollHeight; // Force scroll to the bottom
    }
  }, [chatMessages]);

  useEffect(() => {
    socket.on("chat message", (message) => {
      setChatMessages((prevMessages) => [...prevMessages, message]);
    });

    return () => {
      socket.off("chat message");
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        showSidebar
      ) {
        setShowSidebar(false);
      }
    };

    // Add click event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSidebar]); // Dependency array ensures the effect runs when `showSidebar` changes

  const handleTyping = (e) => {
    setNewMessage(e.target.value);
    // Emit typing event
    socket.emit("typing", { userRole: userRole });
  };

  const fetchOrders = async () => {
    if (!isLoggedIn) {
      toast.error("Please log in to view your orders.");
      return;
    }
    setIsLoading(true); // Start loading

    try {
      const token = localStorage.getItem("token");
      const response = await apiInstance.get("/api/order/fetch", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Assuming the orders are not sorted by the API:
      const sortedOrders = response.data.orders.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setOrders(sortedOrders);
    } catch (error) {
      console.error(
        "Failed to fetch orders:",
        error.response ? error.response.data : error
      );
      toast.error(
        `Failed to load orders: ${
          error.response ? error.response.data.message : "Unknown error"
        }`
      );
    }
    setIsLoading(false); // Start loading
  };

  // Remember to remove or adjust the existing useEffect that calls this logic to avoid duplication

  useEffect(() => {
    fetchOrders();
  }, [isLoggedIn]); // Calls fetchOrders when the component mounts or isLoggedIn changes

  const handleOpenSidebar = (order) => {
    console.log("Opening sidebar for Order ID:", order._id);
    setSelectedOrder(order);
    setChatMessages(order.chat || []);
    setShowSidebar(true);
  };

  const handleCloseSidebar = () => {
    setSelectedOrder(null);
    setShowSidebar(false);
  };

  const downloadImages = async (imageUrls) => {
    if (imageUrls.length === 1) {
      try {
        const response = await fetch(imageUrls[0]);
        const blob = await response.blob();
        // Determine file extension based on MIME type
        let extension = "jpg"; // Default extension
        if (blob.type && blob.type !== "application/octet-stream") {
          const mimeParts = blob.type.split("/");
          if (mimeParts[1]) {
            extension = mimeParts[1].toLowerCase();
          }
        }
        const randomFilename = `image_${new Date().getTime()}_${Math.random()
          .toString(36)
          .substr(2, 9)}.${extension}`;

        // Create a URL for the blob
        const blobUrl = URL.createObjectURL(blob);

        // Use an anchor tag to download the blob
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = randomFilename; // Use the random filename
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // Revoke the blob URL to free up resources
        URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error("Error downloading the image:", error);
      }
    } else {
      const zip = new JSZip();
      await Promise.all(
        imageUrls.map(async (url, index) => {
          try {
            const response = await fetch(url);
            const blob = await response.blob();
            // Fallback extension if mime type is generic
            let extension = "jpg"; // Default extension
            if (blob.type && blob.type !== "application/octet-stream") {
              const mimeParts = blob.type.split("/");
              if (mimeParts[1]) {
                extension = mimeParts[1].toLowerCase();
              }
            }
            zip.file(`image${index + 1}.${extension}`, blob, { binary: true });
          } catch (error) {
            console.error("Error downloading image:", error);
          }
        })
      );

      zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, "images.zip");
      });
    }
  };

  const submitPriceUpdate = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Authentication token not found.");
      return;
    }

    if (
      selectedOrder.price &&
      !window.confirm("The price is already quoted. Do you want to update it?")
    ) {
      return; // User does not confirm the update
    }

    try {
      const response = await apiInstance.post(
        `/api/order/update-price/${selectedOrder._id}`,
        { price: priceValue },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data.order) {
        // Assuming response.data.order contains the updated order data

        // Update the selectedOrder with the new price
        setSelectedOrder(response.data.order);

        // Update the orders array
        setOrders((currentOrders) =>
          currentOrders.map((order) =>
            order._id === response.data.order._id ? response.data.order : order
          )
        );

        setPriceValue(""); // Clear the input field
        toast.success("Price updated successfully");
        socket.emit("price update", {
          orderId: selectedOrder._id,
          newPrice: priceValue,
        });
      } else {
        toast.error("Failed to update price");
      }
    } catch (error) {
      console.error("Error updating the price:", error);
      toast.error("Failed to update price");
    }
  };

  const sendMessage = async () => {
    if (!newMessage.trim()) return;
    const token = localStorage.getItem("token");
    try {
      const response = await apiInstance.post(
        `/api/order/chat/${selectedOrder._id}`,
        { message: newMessage },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data && response.data.chat) {
        // Emit the message to all clients, including the sender, via WebSocket
        // Assuming the server will broadcast this back to all clients
        socket.emit("chat message", {
          message: newMessage,
          senderRole: userRole,
          createdAt: new Date().toISOString(), // Timestamp for the message
        });
        setNewMessage(""); // Clear the input
      } else {
        toast.error("Unexpected response from server");
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      toast.error("Failed to send message");
    }
  };

  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) return;

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await apiInstance.post(
        `/api/order/chat/upload-images/${selectedOrder._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      console.log("Upload response:", response.data);
      if (response.data && response.data.chat) {
        setChatMessages((prevMessages) => [
          ...prevMessages,
          ...response.data.chat,
        ]);
      } else {
        toast.error("Failed to upload images. No chat data returned.");
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      toast.error("Failed to upload images.");
    }
  };

  const updateStatus = async (newStatus) => {
    try {
      const response = await apiInstance.post(
        `/api/order/update-status/${selectedOrder._id}`,
        {
          status: newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data && response.data.order) {
        setSelectedOrder(response.data.order);
        setOrders((currentOrders) =>
          currentOrders.map((order) =>
            order._id === response.data.order._id ? response.data.order : order
          )
        );
        toast.success("Status updated successfully");
        socket.emit("status update", {
          orderId: selectedOrder._id,
          newStatus: newStatus,
        });
      } else {
        toast.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update status");
    }
  };

  const handleDeleteOrder = async (orderId) => {
    if (!window.confirm("Are you sure you want to delete this order?")) {
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await apiInstance.delete(
        `/api/order/delete/${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Order deleted successfully");
        // Filter out the deleted order
        setOrders(orders.filter((order) => order._id !== orderId));
      } else {
        toast.error("Failed to delete order");
      }
    } catch (error) {
      console.error("Error deleting the order:", error);
      toast.error("Failed to delete order");
    }
  };

  const activateDuePayment = async (orderId) => {
    const response = await apiInstance.post(
      `/api/order/update-payment-status/${orderId}`,
      {
        paymentStatus: "Due Payment",
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (response.status === 200) {
      toast.success("Due payment activated");
      // Optionally, refresh order data or update local state
    } else {
      toast.error("Failed to activate due payment");
    }
  };

  return (
    <div>
      <Navbar />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: 1000, // Make sure it's on top
          }}
        >
          <TailSpin color="red" height={50} width={50} />
        </div>
      )}
      <div className="orders-list-container">
        <h2>My Orders</h2>
        <div className="status-filter-buttons">
          <button
            className={statusFilter === "Reviewed" ? "active" : ""}
            onClick={() => setStatusFilter("Reviewed")}
          >
            Reviewed
          </button>
          <button
            className={statusFilter === "In Progress" ? "active" : ""}
            onClick={() => setStatusFilter("In Progress")}
          >
            In Progress
          </button>
          <button
            className={statusFilter === "Completed" ? "active" : ""}
            onClick={() => setStatusFilter("Completed")}
          >
            Completed
          </button>
        </div>

        <ul className="order-list">
          {filteredOrders.map((order, index) => (
            <li
              key={index}
              className="order-card"
              onClick={() => handleOpenSidebar(order)}
            >
              <div className="order-image">
                <img
                  src={order.imageUrls[0]}
                  alt={`Order ${index} reference`}
                />
              </div>
              <div className="order-info">
                <div className="order-info-details">
                  <h3 className="order-info-title">{order.title}</h3>
                  <p className="created-at">
                    Created On: {new Date(order.createdAt).toLocaleDateString()}
                  </p>
                  <p className="order-status">Status: {order.status}</p>
                  <p className="order-price">${order.price}</p>
                </div>
                <p style={{ opacity: 0.3 }}>
                  _________________________________________________________________________________________________
                </p>
                <div className="buttons-container">
                  <button className="view-details-button">View Details</button>
                  {(order.status === "Reviewed" ||
                    order.status === "Pending") && (
                    <button
                      className="delete-button"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the onClick of the parent element
                        handleDeleteOrder(order._id);
                      }}
                    >
                      <i className="fas fa-trash-alt"></i>
                    </button>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {showSidebar && selectedOrder && (
        <div
          ref={sidebarRef}
          className={`sidebar ${showSidebar ? "open" : ""}`}
        >
          <div className="sidebar-content">
            <button className="close-btn" onClick={handleCloseSidebar}>
              <span className="close-icon"></span>
            </button>
            <div className="sidebar-info">
              <h3>Title:</h3>
              <p>{selectedOrder.title}</p>
              <h3>Tracking Id:</h3>
              <p>{selectedOrder._id}</p>
              <h3>Price:</h3>
              {selectedOrder.price ? (
                <p>${selectedOrder.price}</p>
              ) : (
                <p>Not Quoted</p>
              )}
              {userRole === "admin" && (
                <div className="price-update-container">
                  <input
                    type="number"
                    placeholder="Enter price"
                    value={priceValue}
                    onChange={(e) => setPriceValue(e.target.value)}
                    className="input price"
                  />
                  <button
                    onClick={submitPriceUpdate}
                    className="primary-button submit-price-button"
                  >
                    {selectedOrder.price ? "Update Price" : "Submit Price"}
                  </button>
                </div>
              )}
              {userRole === "admin" && (
                <div className="download-images-container">
                  <button
                    onClick={() => downloadImages(selectedOrder.imageUrls)}
                    className="primary-button download-images-button"
                  >
                    Download Images
                  </button>
                </div>
              )}
              <h3>Uploaded Images</h3>
              <div className="images-container">
                {selectedOrder.imageUrls.map((url, idx) => (
                  <a
                    key={idx}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={url}
                      alt={`Detail ${idx}`}
                      className="uploaded-image"
                    />
                  </a>
                ))}
              </div>
              <h3>Notes</h3>
              <p>{selectedOrder.note}</p>
              {userRole === "admin" && (
                <>
                  <h3>Contact Information</h3>
                  <p>
                    {selectedOrder.createdBy?.firstName}{" "}
                    {selectedOrder.createdBy?.lastName}
                  </p>
                  <p> {selectedOrder.createdBy?.emailId}</p>
                </>
              )}
              <div className="review-container">
                <button
                  className="collapsible-header"
                  onClick={togglereviewDetails}
                >
                  <h3>Review</h3>
                  <span>{isreviewOpen ? "▲" : "▼"}</span>
                </button>
                <div
                  className={`collapsible-container ${
                    isreviewOpen ? "open" : ""
                  }`}
                >
                  <div className="review-status">
                    {selectedOrder.price
                      ? "Reviewed"
                      : "You will be quoted soon"}
                  </div>
                </div>
              </div>

              <div className="payment-container">
                <button
                  className="collapsible-header"
                  onClick={togglePaymentDetails}
                >
                  <h3>Payment</h3>
                  <span>{isPaymentOpen ? "▲" : "▼"}</span>
                </button>
                <div
                  className={`collapsible-container ${
                    isPaymentOpen ? "open" : ""
                  }`}
                >
                  <div>
                    {isPaymentOpen && selectedOrder.price != null && (
                      <>
                        {(selectedOrder.status === "Reviewed" ||
                          selectedOrder.status === "Pending") && (
                          <p className="payment-status-message">
                            Deposit ${selectedOrder.price / 2} to get started
                          </p>
                        )}
                        {userRole === "user" ? (
                          selectedOrder.paymentStatus === null ? (
                            <Elements stripe={stripePromise}>
                              <PaymentRequestForm orderId={selectedOrder._id} />
                            </Elements>
                          ) : selectedOrder.paymentStatus === "Due Payment" ? (
                            <>
                              <p className="payment-status-message">
                                Make the due payment so that we can send you the
                                file.
                              </p>
                              <Elements stripe={stripePromise}>
                                <PaymentRequestForm
                                  orderId={selectedOrder._id}
                                />
                              </Elements>
                            </>
                          ) : selectedOrder.paymentStatus === "Half Payment" ? (
                            <p className="payment-status-message">
                              Half Payment Done
                            </p>
                          ) : selectedOrder.paymentStatus === "Full Payment" ? (
                            <p className="payment-status-message">
                              Full Payment Done, will send the file soon
                            </p>
                          ) : (
                            <p className="payment-not-available">
                              Payment not available
                            </p>
                          )
                        ) : (
                          <p className="payment-not-available"></p>
                        )}
                      </>
                    )}
                  </div>

                  {userRole === "admin" &&
                    selectedOrder.paymentStatus === "Half Payment" && (
                      <button
                        onClick={() => activateDuePayment(selectedOrder._id)}
                        className="activate-due-payment-button"
                      >
                        Activate Due Payment
                      </button>
                    )}
                </div>
              </div>

              <div className="chat-container">
                <h3>
                  Chat
                  {typingMessage && (
                    <span className="typing-indicator"> - {typingMessage}</span>
                  )}
                </h3>
                <div className="messages-list" ref={messagesEndRef}>
                  {chatMessages.length > 0 ? (
                    chatMessages.map((msg, idx) => (
                      <div
                        key={idx}
                        className={`message ${
                          msg.senderRole === "admin"
                            ? msg.messageType === "image"
                              ? "message-admin-image"
                              : "message-admin"
                            : msg.messageType === "image"
                            ? "message-user-image"
                            : "message-user"
                        }`}
                      >
                        {msg.messageType === "image" ? (
                          <a
                            href={`${msg.message}?t=${Date.now()}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={`${msg.message}?t=${Date.now()}`}
                              alt="Uploaded content"
                            />
                          </a>
                        ) : (
                          <p>{msg.message}</p>
                        )}
                      </div>
                    ))
                  ) : (
                    <p>No messages yet.</p>
                  )}
                </div>

                <div className="message-input-container">
                  <label htmlFor="fileInput" className="attach-button">
                    <i className="fas fa-paperclip"></i>
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    multiple
                    style={{ display: "none" }}
                    onChange={handleImageUpload}
                    accept="image/*"
                  />
                  <input
                    type="text"
                    className="message-input"
                    value={newMessage}
                    onChange={handleTyping}
                    onKeyPress={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        sendMessage();
                      }
                    }}
                    placeholder="Type your message..."
                  />
                  <button onClick={sendMessage} className="send-button">
                    <i className="fas fa-paper-plane"></i>
                  </button>
                </div>
              </div>
              {userRole === "admin" && (
                <>
                  <div className="status-container">
                    <div
                      className="status-header"
                      onClick={togglestatusDetails}
                    >
                      <h3>Status Update</h3>

                      <button
                        style={{
                          background: "none",
                          border: "none",
                          color: "#fff",
                          cursor: "pointer",
                          marginLeft: "auto", // Pushes the button to the far right
                        }}
                      >
                        {isstatusOpen ? "▲" : "▼"}
                      </button>
                    </div>
                    {isstatusOpen && (
                      <div className="status-button">
                        <button
                          className="primary-button"
                          onClick={() => updateStatus("Reviewed")}
                        >
                          Reviewed
                        </button>
                        <button
                          className="primary-button"
                          onClick={() => updateStatus("In Progress")}
                        >
                          In Progress
                        </button>
                        <button
                          className="primary-button"
                          onClick={() => updateStatus("Completed")}
                        >
                          Completed
                        </button>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default OrdersList;
