import React, { useState, useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import HomePage from "./pages/homepage";
import { apiInstance } from "../src/config/apiConfig";
import Login from "./pages/login";
import Signup from "./pages/signup";
import Forgotpassword from "./pages/forgotpassword";
import Dashboard from "./pages/dashboard/dashboard";
import Create from "./pages/createorder/createorder";
import Order from "./pages/orders/order";
import Settings from "./pages/settings/settings";
import Subscription from "./pages/subscription/subscription";
import User from "./pages/user/user";
import ProtectedRoute from "./pages/ProtectedRoute ";
import { AuthProvider } from "./auth/AuthContext";
import Thankyou from "./pages/thankyou/thankyou";

import "./App.scss";

function App() {
  const [clientId, setClientId] = useState("");

  useEffect(() => {
    apiInstance
      .get("/config/google-client-id")
      .then((response) => {
        console.log("Client ID fetched: ", response.data.clientId); // Debugging line
        setClientId(response.data.clientId);
      })
      .catch((error) => {
        console.error("Failed to fetch client ID", error);
      });
  }, []);

  if (!clientId) {
    return <div>Loading...</div>;
  }

  return (
    <AuthProvider>
      <GoogleOAuthProvider clientId={clientId}>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgotpassword" element={<Forgotpassword />} />
            <Route element={<ProtectedRoute />}>
              <Route path="dashboard" element={<Outlet />}>
                <Route index element={<Dashboard />} />
                <Route path="create" element={<Create />} />
                <Route path="settings" element={<Settings />} />
                <Route path="subscription" element={<Subscription />} />
                <Route path="orders" element={<Order />} />
                <Route path="users" element={<User />} />
                <Route path="thankyou" element={<Thankyou />} />
              </Route>
            </Route>
          </Routes>
        </Router>
      </GoogleOAuthProvider>
    </AuthProvider>
  );
}

export default App;
