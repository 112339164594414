import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "../../sass/setting.scss"; // Ensure this path is correct
import Navbar from "../../components/navbar/Navbar";
import { apiInstance } from "../../config/apiConfig"; 


const Settings = () => {
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    password: "", // Note: Handling passwords in frontend like this is not recommended for real applications
  });
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await apiInstance.get(
          "/api/user/details",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Update this part
        setUserDetails({
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          emailId: response.data.emailId,
        });
      } catch (error) {
        console.error(
          "Error fetching user details:",
          error.response.data.message
        );
        toast.error(error.response.data.message);
      }
    };

    fetchUserDetails();
  }, []);

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    try {
      const response = await apiInstance.put(
        "/api/user/update",
        userDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Assuming the backend responds with the updated user details
      setUserDetails({
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        emailId: response.data.emailId,
      });

      toast.success("User details updated successfully.");
      setEditMode(false); // Exit edit mode
    } catch (error) {
      console.error(
        "Failed to update user details:",
        error.response.data.message
      );
      toast.error("Failed to update user details.");
    }
  };

  return (
    <div className="settings-container">
      <Navbar />

      <h2>User Settings</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="form-section">
          <label htmlFor="firstName">First Name:</label>
          <input
            type="text"
            name="firstName"
            id="firstName"
            value={userDetails.firstName}
            onChange={handleChange}
            disabled={!editMode}
          />
        </div>

        <div className="form-section">
          <label htmlFor="lastName">Last Name:</label>
          <input
            type="text"
            name="lastName"
            id="lastName"
            value={userDetails.lastName}
            onChange={handleChange}
            disabled={!editMode}
          />
        </div>

        <div className="form-section">
          <label htmlFor="emailId">Email ID:</label>
          <input
            type="email"
            name="emailId"
            id="emailId"
            value={userDetails.emailId}
            onChange={handleChange}
            disabled={true}
          />
        </div>

        <div className="button-group">
          <button
            type="button"
            onClick={handleEditToggle}
            className="btn-primary"
          >
            {editMode ? "Cancel" : "Edit"}
          </button>
          {editMode && (
            <button type="submit" className="btn-primary">
              Save
            </button>
          )}
        </div>
      </form>
 <ToastContainer
              position="bottom-center"
              autoClose={2000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            /> <ToastContainer
              position="bottom-center"
              autoClose={2000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />    </div>
  );
};

export default Settings;
