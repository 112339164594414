import React, { useState } from "react";
import "../../../../sass/galleryslider.scss"; // Your custom carousel styles
import image1 from "../../../../asset/gallerycarousel/1.webp";
import image2 from "../../../../asset/gallerycarousel/2.webp";
import image3 from "../../../../asset/gallerycarousel/3.webp";
import image4 from "../../../../asset/gallerycarousel/4.webp";
import image5 from "../../../../asset/gallerycarousel/5.webp";
import image6 from "../../../../asset/gallerycarousel/6.webp";
import image7 from "../../../../asset/gallerycarousel/7.webp";
import image8 from "../../../../asset/gallerycarousel/8.webp";
import image9 from "../../../../asset/gallerycarousel/9.webp";
import image10 from "../../../../asset/gallerycarousel/10.webp";
import image11 from "../../../../asset/gallerycarousel/11.webp";
// Import other images as needed

export default function App() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    // Add other images here
  ];

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  const slideWidth = 410; // Width of movement in one click (in pixels)

  return (
    <div className="carousel">
      <button className="prev" onClick={prevSlide}>
        &#10094;
      </button>
      <button className="next" onClick={nextSlide}>
        &#10095;
      </button>
      <div
        className="carousel-inner"
        style={{ transform: `translateX(-${currentSlide * slideWidth}px)` }}
      >
        {slides.map((slide, index) => (
          <div key={index} className="slide">
            <img src={slide} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
}
