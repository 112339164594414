import React, { useState } from "react";
import "../sass/homepage.scss";
import "../sass/signup.scss";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate, Link } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { apiInstance } from "../config/apiConfig"; 

function Signup() {
  const navigate = useNavigate();
  const [emailId, setEmailId] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [resendTimer, setResendTimer] = useState(0);

  const handleSendOtp = async () => {
    if (!isEmail(emailId)) {
      toast.error("Please enter a valid email address.");
      return;
    }
    try {
      const response = await apiInstance.post(
        "/api/otp/send-otp",
        {
          email: emailId,
        }
      );
      if (response.status === 200) {
        setOtpSent(true);
        toast.success("OTP sent successfully.");
        // Reset OTP input fields if OTP is being resent
        setOtp(["", "", "", "", "", ""]);
        setResendTimer(90); // Start the timer for 90 seconds
        let interval = setInterval(() => {
          setResendTimer((prevTimer) => {
            if (prevTimer <= 1) {
              clearInterval(interval);
              return 0;
            }
            return prevTimer - 1;
          });
        }, 1000);
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        // User already exists
        toast.error("User already exists.");
      } else {
        // Handle other errors
        toast.error("Error sending OTP.");
      }
    }
  };

  const handleVerifyOtp = async () => {
    // Assume emailId is the state variable that holds the user's email
    // and combinedOtp is the string that represents the OTP entered by the user
    const verificationData = {
      email: emailId,
      otp: otp.join(""), // Assuming otp is an array of individual digits
    };

    try {
      const response = await apiInstance.post(
        "/api/otp/verify-otp",
        verificationData
      );

      if (response.data.message === "OTP verified successfully") {
        setOtpVerified(true);
        toast.success("OTP verified successfully.");
        // You can navigate the user to another page or perform further actions here
      } else {
        // Handle any messages that might indicate success but use different wording
        toast.error("Unexpected response, please try again.");
      }
    } catch (error) {
      // It's a good idea to provide more specific error messages based on the error response
      // For example, if the server returns a 400 status, you might say the OTP was invalid or expired
      if (error.response && error.response.status === 400) {
        toast.error("Invalid or expired OTP.");
      } else {
        toast.error("Error verifying OTP. Please try again.");
      }
    }
  };

  const handleRegister = async () => {
    try {
      await apiInstance.post("api/user/register", {
        firstName,
        lastName,
        emailId,
        password,
      });
      toast.success("Registration successful.");
      navigate("/dashboard"); // Redirect the user
    } catch (error) {
      console.log(error);
      toast.error("Registration failed. Please try again.");
    }
  };

  const handleOtpChange = (value, index) => {
    let newOtp = [...otp];

    // Handle paste of complete OTP
    if (
      value.length > 1 &&
      /^[0-9]+$/.test(value) &&
      value.length <= newOtp.length
    ) {
      newOtp = value
        .split("")
        .slice(0, newOtp.length)
        .map((digit) => digit.trim());
      setOtp(newOtp);
      // Focus the next input if not the last one
      if (index < newOtp.length - 1) {
        const nextSibling = document.querySelector(
          `input[name='otp${newOtp.length}']`
        );
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
      return;
    }

    // Normal digit input
    if (value) {
      newOtp[index] = value[0]; // Take only the first character
    } else {
      // If backspacing from an empty field, move back to the previous field if it exists
      newOtp[index] = "";
      if (index > 0) {
        const prevSibling = document.querySelector(`input[name='otp${index}']`);
        if (prevSibling !== null) {
          prevSibling.focus();
        }
      }
    }

    setOtp(newOtp);

    // Automatically move focus to next input if value is entered
    if (value && index < newOtp.length - 1) {
      const nextSibling = document.querySelector(
        `input[name='otp${index + 2}']`
      );
      if (nextSibling !== null) {
        nextSibling.focus();
      }
    }
  };

  // Combine OTP digits into a single string
  const combinedOtp = otp.join("");

  return (
    <section id="body">
      <div className="signup">
        <div className="flex justify-center items-center min-h-screen">
          <div className="card w-full max-w-md bg-base-100 shadow-xl">
            <div className="card-body">
              <div className="button-container">
                <button
                  onClick={() => navigate("/login")}
                  className="back-button text-xl font-bold icon-align"
                >
                  <AiOutlineLeft />
                  Back
                </button>
                <button
                  onClick={() => navigate("/login")}
                  className="signup-button"
                >
                  Login
                </button>
              </div>

              {!otpSent ? (
                <>
                  <div className="form-control">
                    <label className="label">
                      <h2>Email Address</h2>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter your email"
                      className="input input-bordered w-full"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                    />
                  </div>
                  <div className="form-control mt-6">
                    <button
                      className="secondary-button"
                      onClick={handleSendOtp}
                    >
                      Send OTP
                    </button>
                  </div>
                </>
              ) : !otpVerified ? (
                <>
                  <div className="form-control">
                    <label className="label">OTP</label>
                    <div className="otp-inputs-container">
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          type="text"
                          name={`otp${index + 1}`}
                          maxLength="1"
                          value={digit}
                          onChange={(e) =>
                            handleOtpChange(e.target.value, index)
                          }
                          onPaste={(e) =>
                            handleOtpChange(
                              e.clipboardData.getData("text"),
                              index
                            )
                          }
                          className="otp-input w-10 text-center"
                          pattern="\d*"
                          autoFocus={index === 0}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="form-control mt-6">
                    <button
                      className="secondary-button"
                      onClick={handleVerifyOtp}
                    >
                      Verify OTP
                    </button>
                  </div>
                  {resendTimer > 0 ? (
                    <p className="text-center mt-4">
                      Please wait for {resendTimer} seconds to resend OTP.
                    </p>
                  ) : (
                    <div className="text-center mt-4">
                      <button
                        className="secondary-button"
                        onClick={handleSendOtp}
                      >
                        Resend OTP
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <input
                    type="text"
                    placeholder="First Name"
                    className="input input-bordered"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="input input-bordered"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <input
                    type="password"
                    placeholder="Password"
                    className="input input-bordered"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="form-control mt-6">
                    <button
                      className="secondary-button"
                      onClick={handleRegister}
                    >
                      Register
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
   <ToastContainer
              position="bottom-center"
              autoClose={2000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            /> <ToastContainer
              position="bottom-center"
              autoClose={2000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />      </div>
    </section>
  );
}

export default Signup;
