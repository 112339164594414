import React from "react";
import Navbar from "../../components/navbar/Navbar";



const Dashboard = ({  }) => {
 

  return (
    <div className="flex h-auto bg-[#06011a] w-screen max-w-screen">
     <Navbar/>
        
    </div>
  );
};

export default Dashboard;
