import React from "react";
import "../../../sass/gallery.scss";
import Carousel from "./component/GalleryCarousel";
import threeds from "../../../asset/galleryicons/Frame 8.svg";
import zbrush from "../../../asset/galleryicons/Frame 9.svg";
import maya from "../../../asset/galleryicons/Frame 10.svg";
import unity from "../../../asset/galleryicons/Frame 11.svg";

import { useRef } from "react";

const Gallery = () => {
  const ref = useRef();
  return (
    <div>
      <section className="hero gallery-section " id="gallery">
        <div className="hero-body">
          <p className="gallery-title">Gallery</p>
          <br /> <br />
          <div className="grid grid-cols-1 gap-4 items-center">
            <div className="flex items-center flex-col pt-10">
              {/* <img src={tools} alt="" /> */}
              <Carousel />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Gallery;
