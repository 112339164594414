import React, { createContext, useContext, useEffect, useState } from "react";
import {jwtDecode} from "jwt-decode"; 

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const expirationTime = decodedToken.exp * 1000;
        if (Date.now() >= expirationTime) {
          localStorage.removeItem("token");
          localStorage.removeItem("role");
          setIsLoggedIn(false);
        } else {
          setIsLoggedIn(true);
          const role = localStorage.getItem("role");
          if (role) {
            setUserRole(role);
          }
        }
      } catch (error) {
        // Handle errors for token decode here, such as if the token is malformed
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        setIsLoggedIn(false);
      }
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, userRole, setUserRole }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
