import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../sass/user.scss";
import { apiInstance } from "../../config/apiConfig";
import Navbar from "../../components/navbar/Navbar";

function Users() {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const response = await apiInstance.get("/api/user/all-users", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (response.status === 200) {
          setUserData(response.data);
          toast.success("User data fetched successfully.");
        } else {
          toast.error("Failed to fetch user data.");
        }
      } catch (error) {
        console.error("Error fetching all users:", error);
        toast.error("Error fetching user data.");
      }
    };

    fetchAllUsers();
  }, []); // Empty dependency array ensures this runs once on mount

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };

  return (
    <div>
      <Navbar />
      <div className="users-list-container">
        <h2>User List</h2>
        <div className="user-list">
          {userData.map((user, index) => (
            <div key={index} className="user-card">
              <div className="user-info">
                <h3>{`${user.firstName} ${user.lastName}`}</h3>
                <p>{user.emailId}</p>
                <p>{formatDate(user.createdAt)}</p>
                <p>{user.role}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Users;
