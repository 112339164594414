import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import logo from "../../asset/logo_white.png";
import { CgClose } from "react-icons/cg";
import { HiMenuAlt4 } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/AuthContext";
import "../../sass/navbar.scss";

const Navbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isNavbarScrolled, setIsNavbarScrolled] = useState(false);
  const { isLoggedIn, setIsLoggedIn, userRole } = useAuth();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 50) {
        setIsNavbarScrolled(true);
      } else {
        setIsNavbarScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    setIsLoggedIn(false);
    navigate("/");
  };

  return (
    <nav
      className={`marque-navbar ${
        isNavbarScrolled ? "navbar-active" : "navbar"
      }`}
    >
      <div className="navbar-contents">
        <NavLink to="/" className="nav-logo">
          <img
            src={logo}
            alt="nav-logo"
            className="nav-logo-image"
            onClick={() => navigate("/")}
          />
        </NavLink>
        <div
          className={`menu-icon ${isOpen ? "open" : ""}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? <CgClose /> : <HiMenuAlt4 />}
        </div>
        <ul className={`nav-links ${isOpen ? "active" : ""}`}>
          {!isLoggedIn ? (
            <>
              <li>
                <NavHashLink to="#track">Track</NavHashLink>
              </li>
              <li>
                <NavLink to="/dashboard/subscription">Subscription</NavLink>
              </li>
              <li>
                <NavHashLink to="#gallery">Gallery</NavHashLink>
              </li>
              <li>
                <NavHashLink to="#review">Review</NavHashLink>
              </li>
              <li>
                <NavLink to="/login">Login</NavLink>
              </li>
            </>
          ) : userRole === "admin" ? (
            <>
              <li>
                <NavLink to="/dashboard/create-coupon">Create Coupon</NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/orders">Orders</NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/users">Users</NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/subscription">Subscription</NavLink>
              </li>
              <li>
                <button onClick={handleLogout}>Logout</button>
              </li>
            </>
          ) : (
            <>
              <li>
                <NavLink to="/dashboard/create">Create New</NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/orders">Orders</NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/settings">Settings</NavLink>
              </li>
              <li>
                <NavLink to="/dashboard/subscription">Subscription</NavLink>
              </li>
              <li>
                <button onClick={handleLogout}>Logout</button>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
