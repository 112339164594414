import React, { useState, useEffect } from "react";
import "../sass/homepage.scss";
import "../sass/login.scss";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate, Link } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiHide, BiShowAlt } from "react-icons/bi";
import axios from "axios";
import { FcGoogle } from "react-icons/fc"; // This imports a Google icon from `react-icons`
import { useAuth } from "../auth/AuthContext";
import { ToastContainer } from "react-toastify";
import { apiInstance } from "../config/apiConfig";
import { useGoogleLogin } from "@react-oauth/google";
import { TailSpin } from "react-loader-spinner";


function Login() {
  const navigate = useNavigate();
  const [visible, setVisibility] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [isRemember, setIsRemember] = useState(true);
  const { isLoggedIn, setIsLoggedIn, setUserRole } = useAuth();
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    // Check if there is an existing token in local storage
    const token = localStorage.getItem("token");
    if (token) {
      // If token exists, check role
      const role = localStorage.getItem("role");
      if (role === "admin") {
        navigate("/dashboard/orders"); // Redirect admin to orders dashboard
      } else {
        navigate("/dashboard/orders"); // Redirect regular user to create dashboard
      }
    }
  }, [navigate]);

  const togglePassword = () => {
    setVisibility(!visible);
  };

  const handleRememberMeChange = () => {
    setIsRemember(!isRemember);
  };

  const handleSubmit = async () => {
    if (!emailId || !isEmail(emailId) || !password) {
      toast.error("Please enter valid email and password");
      return;
    }

    setLoading(true);
    try {
      const response = await apiInstance.post("/api/user/login", {
        emailId,
        password,
      });
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("role", response.data.role);
      setIsLoggedIn(true);
      setUserRole(response.data.role);
      navigate(response.data.role === "admin" ? "/dashboard/orders" : "/dashboard/create");
      toast.success("Login successful");
    } catch (error) {
      toast.error(error.response?.data.message || "Login failed. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (response) => {
      setLoading(true);
      try {
        const res = await apiInstance.post("/api/user/auth/google", { code: response.code });
        localStorage.setItem("token", res.data.token);
        setIsLoggedIn(true);
        setUserRole(res.data.user.role);
        navigate("/dashboard/orders");
        toast.success("Login successful");
      } catch (error) {
        toast.error(error.message || "Google login failed. Please try again.");
      } finally {
        setLoading(false);
      }
    },
    onError: () => {
      toast.error("Google login failed. Please try again.");
    },
  });

  return (
    <section id="body">
      <div className="login">

      {loading && (
        <div
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: 1000, // Make sure it's on top
          }}
        >
          <TailSpin color="red" height={50} width={50} />
        </div>
      )}
        <div className="flex justify-center items-center h-screen">
          <div className="card w-96 bg-base-100 shadow-xl p-5">
            <div className="card-body">
              <div className="button-container">
                <button
                  onClick={() => navigate("/")}
                  className="back-button text-xl font-bold icon-align" // Apply custom class for back button
                >
                  <AiOutlineLeft />
                </button>
                <button
                  onClick={() => navigate("/signup")}
                  className="signup-button" // Apply custom class for signup button
                >
                  Signup
                </button>
              </div>
              <div className="form-control">
                <label className="label">
                  <h2>Email</h2>
                </label>
                <input
                  type="text"
                  placeholder="email"
                  className="input input-bordered"
                  value={emailId}
                  onChange={(e) => setEmailId(e.target.value)}
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <h2>Password</h2>
                </label>

                <input
                  type={visible ? "text" : "password"}
                  placeholder="password"
                  className="input input-bordered" // Ensure padding is sufficient for the icon
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="absolute ">
                  <button
                    onClick={togglePassword}
                    type="button"
                    className="text-gray-400 hover:text-gray-500 focus:outline-none"
                  >
                    {visible ? <BiHide /> : <BiShowAlt />}
                  </button>
                </div>
              </div>
              <div className="checkbox-forgot-row">
                {" "}
                {/* New class for this specific row */}
                <div className="checkbox-label-container flex items-center">
                  {" "}
                  {/* Ensure this container uses flex */}
                  <input
                    id="rememberMe" // Good practice to use IDs for accessibility
                    type="checkbox"
                    checked={isRemember}
                    onChange={handleRememberMeChange}
                    className="checkbox"
                  />
                  <label htmlFor="rememberMe" className="remember">
                    Remember me
                  </label>
                </div>
                <Link to="/forgotpassword" className="link">
                  Forgot password?
                </Link>
              </div>

              <div className="form-control mt-6">
                <button className="secondary-button" onClick={handleSubmit}>
                  Login
                </button>
              </div>
              <div className="form-control mt-6">
                <button
                  onClick={() => googleLogin()}
                  className="google-login-button"
                >
                  <FcGoogle className="icon" /> Login with Google
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </section>
  );
}

export default Login;
