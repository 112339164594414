import React, { useState } from "react";
import "../sass/homepage.scss";
import "../sass/forgotpassword.scss";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate, Link } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function Forgotpassword() {
  const navigate = useNavigate();
  const [visible, setVisibility] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [isRemember, setIsRemember] = useState(true);

  const togglePassword = () => {
    setVisibility(!visible);
  };

  const handleRememberMeChange = () => {
    setIsRemember(!isRemember);
  };

  const handleSubmit = () => {
    if (!emailId || !isEmail(emailId) || !password) {
      toast.error("Please enter valid email and password");
      return;
    }
    toast.success("Login Successful");
    // Further processing here, like navigate('/dashboard');
  };

  return (
    <section id="body">
      <div className="forgotpassword">
        <div className="flex justify-center items-center h-screen">
          <div className="card w-96 bg-base-100 shadow-xl p-5">
            <div className="card-body">
              <div className="button-container">
                <button
                  onClick={() => navigate("/login")}
                  className="back-button text-xl font-bold icon-align" // Apply custom class for back button
                >
                  <AiOutlineLeft />
                </button>
                <button
                  onClick={() => navigate("/login")}
                  className="signup-button" // Apply custom class for signup button
                >
                  Login
                </button>
              </div>
              <div className="form-control">
                <label className="label">
                  <h2>Email Address</h2>
                </label>
                <input
                  type="text"
                  placeholder="email"
                  className="input input-bordered"
                  value={emailId}
                  onChange={(e) => setEmailId(e.target.value)}
                />
              </div>

              <div className="form-control mt-6">
                <button className="secondary-button" onClick={handleSubmit}>
                  Send OTP
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />{" "}
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />{" "}
    </section>
  );
}

export default Forgotpassword;
