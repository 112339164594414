import React from 'react';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/AuthContext';
import '../../sass/SubscriptionPage.scss'; // Import a custom SCSS file for styling

function SubscriptionPage() {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();

  const handleSubscribeClick = (plan) => {
    if (isLoggedIn) {
      navigate(`/dashboard/subscribe/${plan}`);
    } else {
      navigate('/login');
    }
  };

  return (
    <section id="subscribe-section" className="subscription-page">
      <Navbar />
      <div className="hero">
        <div className="hero-body">
          <div className="container">
            <div className="title-section">
              <h1 className="title">Subscribe for Exclusive Benefits</h1>
              <h2 className="subtitle">
                Gain access to premium designs and private commissions starting at just $299
              </h2>
            </div>
            <div className="cards-container">
              {['Bronze', 'Silver', 'Gold'].map((plan, index) => (
                <div key={index} className="card">
                  <div className="card-body">
                    <h5 className="card-title">{plan} Plan</h5>
                    <p className="card-text">
                      {plan === 'Bronze' ? '5 private commissions per month' 
                      : plan === 'Silver' ? '10 private commissions per month' 
                      : '15 private commissions per month'}
                    </p>
                    <ul className="card-text" style={{ textAlign: 'left' }}>
                      <li>Delivery in 4-7 days</li>
                      <li>Deliverable Format: .3dm</li>
                    </ul>
                    <p className="card-text price">Price:  
                      {plan === 'Bronze' ? '$299/month' 
                      : plan === 'Silver' ? '$599/month' 
                      : '$999/month'}
                    </p>
                    <button
                      className="subscribe-button"
                      onClick={() => handleSubscribeClick(plan.toLowerCase())}
                    >
                      Subscribe Now
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}

export default SubscriptionPage;
