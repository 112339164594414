import React, { useState, useEffect } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import "../../sass/orders.scss";
import { apiInstance } from "../../config/apiConfig";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom"; // Import useNavigate instead of useHistory

export default function CheckoutForm({ orderId }) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate(); // Get navigate function for programmatic navigation

  useEffect(() => {
    // Fetch the current payment status when the component mounts
    fetchOrderDetails();
  }, [orderId]); // Dependency array includes orderId to refetch if it changes

  const fetchOrderDetails = async () => {
    setIsLoading(true);
    try {
      const response = await apiInstance.get(`/api/order/details/${orderId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      if (response.data) {
        setIsLoading(false);
        return response.data.order.paymentStatus; // Return payment status directly
      } else {
        toast.error("Failed to fetch order details.");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch order details:", error);
      toast.error("Failed to fetch order details.");
      setIsLoading(false);
    }
    return null; // Return null if there was an error or no data
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Make sure stripe and elements are loaded
      return;
    }
    setIsLoading(true);

    const cardElement = elements.getElement(PaymentElement); // Get the PaymentElement from elements

    if (!cardElement) {
      setMessage("Payment element not found.");
      setIsLoading(false);
      return;
    }

    const currentPaymentStatus = await fetchOrderDetails(); // Fetch the current payment status

    const newPaymentStatus =
      currentPaymentStatus === "Due Payment" ? "Full Payment" : "Half Payment";
    const newOrderStatus =
      currentPaymentStatus === "Due Payment" ? "Completed" : "In Progress";

    // Confirm payment passes the cardElement and additional parameters
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      element: cardElement,
      confirmParams: {
        return_url: `${window.location.origin}/dashboard/orders`,
      },
      redirect: "if_required",
    });

    if (error) {
      setMessage(error.message);
      setIsLoading(false);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      await updateOrderPaymentStatus(orderId, newPaymentStatus); // Update payment status based on decision above
      await updateOrderStatus(orderId, newOrderStatus); // Update order status based on the payment status
      setMessage("Payment succeeded!");
      await updateOrderStatus(); // Update order status to 'In Progress'
      navigate("/dashboard/thankyou"); // Navigate after update and payment success
    } else {
      // Handle other outcomes such as paymentIntent status not succeeded
      navigate("/retry-payment"); // Adjust this URL as necessary
    }
  };

  const updateOrderStatus = async (orderId, newStatus) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiInstance.post(
        `/api/order/update-status/${orderId}`,
        { status: newStatus },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data) {
        toast.success(`Order status updated to ${newStatus}`);
      } else {
        toast.error("Failed to update order status: No data received");
      }
    } catch (error) {
      console.error("Failed to update order status:", error);
      toast.error("Failed to update order status");
    }
  };

  const updateOrderPaymentStatus = async (orderId, status) => {
    try {
      const response = await apiInstance.post(
        `/api/order/update-payment-status/${orderId}`,
        {
          paymentStatus: status,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.data) {
        toast.success("Payment status updated successfully");
      } else {
        toast.error("Failed to update payment status");
      }
    } catch (error) {
      console.error("Failed to update payment status:", error);
      toast.error("Failed to update payment status");
    }
  };

  return (
    <form className="payment_content" onSubmit={handleSubmit}>
      <div className="payment-element-container">
        <PaymentElement />
      </div>
      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        className="payment-content-button"
      >
        {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
      </button>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
