import React from "react";
import '../../../sass/faq.scss'; // Importing the SCSS file


const Faq = () => {
  const faqs = [
    {
      question: "What do I need to start?",
      answer: "Just 2-3 images to begin creating your 3dm file.",
    },
    {
      question: "What if I have only one image?",
      answer: "A single image is sufficient to start the process.",
    },
    {
      question: "Can you convert any image?",
      answer: "Yes, we can convert any image into a 3dm file.",
    },
    {
      question: "What's the cost?",
      answer: "The rate is a flat $60 per 3dm file.",
    },
    {
      question: "How long does it take?",
      answer: "Expect your design in 3-4 working days.",
    },
    {
      question: "How many revisions do I get?",
      answer: "Up to three revisions are included.",
    },
    {
      question: "Is there a quality guarantee?",
      answer: "Absolutely, we ensure high-quality final designs.",
    },
  ];
  

  return (
    <div className="faq-container">
      <div className="faq-box">
        <div className="faq-title">FAQ's</div>
        {faqs.map((item, index) => (
          <div key={index} className="faq-item">
            <div className="collapse">
              <input type="checkbox" className="peer" />
              <div className="collapse-title">
                {item.question}
              </div>
              <div className="collapse-content">
                <p className="answer">
                  {item.answer}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
