import axios from "axios";
import { toast } from "react-toastify";


// Define a single variable to switch between environments
const isLiveEnvironment = true; // Set to false when you want to use the test environment

// Use the variable to choose between the test and live URLs
const BASE_URL = isLiveEnvironment
  ? "https://verjew-api.onrender.com"
  : "http://localhost:3001";

// Create an instance of axios with the chosen base URL
const apiInstance = axios.create({
  baseURL: BASE_URL,

});

apiInstance.interceptors.response.use(response => response, error => {
  if (error.response && (error.response.status === 401 || error.response.status === 403)) {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    window.location.href = '/login'; // Redirect to login
    toast.error("Session expired. Please login again.");
  }
  return Promise.reject(error);
});

export { apiInstance };
