import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../sass/ThankYou.scss'; // Import only if you're using a separate stylesheet

const ThankYou = () => {
  const navigate = useNavigate();

  return (
    <div className="thank-you-container"> 
      <h1>Thank You</h1>
      <p>Your payment has been successfully processed.</p>
      <button className="primary-button" onClick={() => navigate('/dashboard/orders')}>
        Dashboard
      </button>
    </div>
  );
};

export default ThankYou;
