// PaymentComponent.js
import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./checkout"; // Adjust the path as needed
import { apiInstance } from "../../config/apiConfig"; // Adjust the path as needed

const stripePromise = loadStripe(
  "pk_test_51MVvSRKDWovJoqmyX3JJuu4hFVfRUGKI97wVzd3ZWLzGlxNuoZo6McEm2n8L76XM4kpEJLX50uQ7PZrn4K4bDwUx00pCm6988g"
);
const PaymentComponent = ({ orderId }) => {
  const [clientSecret, setClientSecret] = useState("");
  const appearance = { theme: "flat" };

  useEffect(() => {
    const fetchClientSecret = async () => {
      const token = localStorage.getItem("token");
      const response = await apiInstance.post(
        `/api/order/create-payment-intent/${orderId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setClientSecret(response.data.clientSecret);
    };

    fetchClientSecret();
  }, [orderId]);

  return (
    <div className="payment">
      {clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
          <CheckoutForm orderId={orderId} />
        </Elements>
      )}
    </div>
  );
};

export default PaymentComponent;
