import React from "react";
import "../../../sass/create.scss";
import projectdata from "../../../json-data/project.json";
import logo from "../../../asset/Vector(5).png";

import { useNavigate } from "react-router-dom";

const Create = () => {
  const navigate = useNavigate();

  const openTalkToExperts = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div>
      <section className="hero create-section" id="show-process">
        <div className="hero-body">
          <div className="points-container">
            <div className="create-main-text text-right lg:text-left">
              Easy <br />
              Simplified <br />
              Process <br />
            </div>
            {projectdata.map((point) => (
              <div key={point.id} className="point">
                <h3 className="point-title">{point.title}</h3>
                <p className="point-subtitle">{point.subtitle}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Create;
