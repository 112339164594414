import React from "react";
import "../../sass/policy.scss";


const PrivacyPolicyPage = () => {
  return (
    <div className="privacy-policy">
      <h1 className="text-2xl font-bold mb-4 text-center">Privacy Policy</h1>
      <p className="text-justify">
        This Privacy Policy describes how we collects, uses, and shares personal
        information of users of this website.
      </p>

      <h2 className="text-lg font-bold mb-2">1. Information We Collect:</h2>
      <p className="text-justify">
        We may collect the following types of information:
      </p>
      <ul className="pl-4 list-disc">
        <li>
          Personal Information: Name, email address, and any other information
          voluntarily provided by the user.
        </li>
        <li>
          Usage Data: Information about how you use our website, including IP
          address, browser type, and pages visited.
        </li>
      </ul>

      <h2 className="text-lg font-bold mb-2">
        2. How We Use Your Information:
      </h2>
      <p className="text-justify">
        We may use the collected information for the following purposes:
      </p>
      <ul className="pl-4 list-disc">
        <li>To provide, maintain, and improve our website.</li>
        <li>
          To send periodic emails regarding your use of our services or to
          respond to inquiries and requests.
        </li>
      </ul>

      <h2 className="text-lg font-bold mb-2">
        3. How We Share Your Information:
      </h2>
      <p className="text-justify">
        We do not sell, trade, or otherwise transfer your personal information
        to third parties without your consent, except as described below:
      </p>
      <ul className="pl-4 list-disc">
        <li>
          Third-Party Service Providers: We may share your information with
          third-party service providers to assist us in providing and improving
          our services.
        </li>
        <li>
          Legal Compliance: We may disclose your information to comply with
          applicable laws, regulations, or legal processes.
        </li>
      </ul>
      <h2 className="text-lg font-bold mb-2">4. Your Choices:</h2>
      <p className="text-justify">
        You can choose not to provide personal information, although it may be
        required to access certain features of our website.
      </p>

      <h2 className="text-lg font-bold mb-2">5. Cookies:</h2>
      <p className="text-justify">
        We use cookies to enhance your experience on our website. You can set
        your browser to refuse cookies or to alert you when cookies are being
        sent.
      </p>

      <h2 className="text-lg font-bold mb-2">6. Security:</h2>
      <p className="text-justify">
        We take reasonable measures to protect your personal information from
        unauthorized access, disclosure, alteration, or destruction.
      </p>

      <h2 className="text-lg font-bold mb-2">7. Links to Other Websites:</h2>
      <p className="text-justify">
        Our website may contain links to third-party websites. We are not
        responsible for the privacy practices or content of these third-party
        sites.
      </p>

      <h2 className="text-lg font-bold mb-2">
        8. Changes to this Privacy Policy:
      </h2>
      <p className="text-justify">
        We may update this Privacy Policy from time to time. The date of the
        latest revision will be indicated at the top of the page.
      </p>

      <h2 className="text-lg font-bold mb-2">9. Contact Us:</h2>
      <p className="text-justify">
        If you have any questions about this Privacy Policy, please contact us
        at project@3dspot.io.
      </p>
    </div>
  );
};

export default PrivacyPolicyPage;
